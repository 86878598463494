<template>
  <div class="main">
    <div class="main-config sticky-bar">
      <template v-if="srvJson">
        <v-toolbar style="box-shadow: unset; height: 40px">
          {{ selectedElement.name }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                fab
                icon
                dark
                small
                @click="applyConfig"
              >
                <v-icon :color="somethingchange ? '#2a51e8' : 'grey'">mdi-content-save-all</v-icon>
              </v-btn>
            </template>
            <span>Apply Config</span>
          </v-tooltip>
        </v-toolbar>
        <div class="config-form" style="height: 50vh; overflow-y: auto">
          <SurveyInstance
            class="kbet-survey k-mokapos elem-confi"
            :json="srvJson"
            @setHandler="
              (h) => {
                srvHandler = h
              }
            "
            @onInput="onChangeInput"
          />
        </div>
      </template>
      <div v-else class="warning-message">
        <h5>First Select Type From First tab..</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers as cnh } from 'vuex'
import transform from './Configurations/ElementsAvailble/transformForChart'
import sanitizeHtml from 'sanitize-html'
// const { mapGetters, mapMutations } = cnh('ElmentBuilderStore')
const { mapGetters, mapMutations, mapActions } = cnh('ElmentBuilderStore')
export default {
  data() {
    return {
      openChartMaker: false,
      defaultSrvConfiguration: {
        showQuestionNumbers: 'off',
        showNavigationButtons: false,
        showCompletedPage: false,
        // questionTitleLocation: 'left',
        pages: []
      },
      srvJson: false,
      showSurvey: false,
      srvHandler: false,
      tabular_data: {
        columns: [],
        rows: []
      },
      somethingchange: false
    }
  },
  components: {
    chartMaker: () => import('../../elements-components/Graphs/ChartMaker.vue')
  },
  computed: {
    ...mapGetters(['selectedElement']),
    renderChartMaker() {
      return 'chartMaker'
    }
  },
  methods: {
    ...mapMutations(['setSelectedElementsProps']),
    ...mapActions(['getExportData']),
    applyConfig(){
      if (this.srvHandler.isCurrentPageHasErrors) return
      this.saveChangesInLocalStore()
    },
    saveChangesInLocalStore() {
      // console.log("this.srvHandler.data",this.srvHandler.data)
      // if (this.srvHandler.isCurrentPageHasErrors) return
      if (this.selectedElement && this.selectedElement.type == 'graph') {
        console.log('this.srvHandler.data', this.srvHandler.data)
        let previewData = transform(this.srvHandler)
        console.log(previewData, '+++++++++++++')
        this.setSelectedElementsProps({
          prop: 'configuration',
          value: previewData
        })
        this.$store.commit('ReportBuilder/setLocalVersionOfElementsData', {
          prop: 'configuration',
          data: previewData,
          _id: this.selectedElement._id
        })
      } else {
        let data = this.srvHandler.data
        if (this.selectedElement.type && this.selectedElement.type == 'html') {
          if (data.text) {
            data.text = sanitizeHtml(data.text, {
              allowedAttributes: {
                '*': ['style'],
                img: ['src']
                // a: [ 'href', 'name', 'target' ],
              },
              allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
            })
          }
        }
        this.setSelectedElementsProps({
          prop: 'configuration',
          value: data
        })
        this.$store.commit('ReportBuilder/setLocalVersionOfElementsData', {
          prop: 'configuration',
          data: data,
          _id: this.selectedElement._id
        })
      }
      this.somethingchange = false;
    },
    async getConfig() {
      try {
        if (
          this.selectedElement &&
          this.selectedElement.type !== 'graph' &&
          this.selectedElement.subType
        ) {
          let { type, subType } = this.selectedElement
          let srvJson_conf = require(`./Configurations/JsonConfig/${type}/${subType}.json`)
          this.srvJson = {
            ...this.defaultSrvConfiguration,
            pages: [
              {
                name: '_',
                elements: srvJson_conf
              }
            ]
          }
          setTimeout(() => {
            this.initialPayload()
          }, 1000)
        } else if (
          this.selectedElement &&
          this.selectedElement.type == 'graph'
        ) {
          let { type, subType } = this.selectedElement
          if (subType == 'column_bar_chart' || subType == 'line_chart') {
            let srvJson_conf = require(`./Configurations/JsonConfig/${type}/column_bar_chart.json`)
            this.srvJson = {
              ...this.defaultSrvConfiguration,
              pages: [
                {
                  name: '_',
                  elements: srvJson_conf
                }
              ]
            }
          } else if (subType == 'pie_chart') {
            let srvJson_conf = require(`./Configurations/JsonConfig/${type}/pie_chart.json`)
            this.srvJson = {
              ...this.defaultSrvConfiguration,
              pages: [
                {
                  name: '_',
                  elements: srvJson_conf
                }
              ]
            }
          }
          setTimeout(() => {
            this.initialPayload()
          }, 1000)
        } else {
          this.srvJson = false
          return false
        }
      } catch (error) {
        console.log(error)
        this.srvJson = {}
      }
    },
    initialPayload() {
      if (this.selectedElement.type == 'graph') {
        if (
          this.selectedElement.configuration &&
          this.selectedElement.configuration.formData
        ) {
          this.srvHandler.data = this.selectedElement.configuration.formData
        } else {
          this.srvHandler.data = this.selectedElement.configuration
          let { graphType } = this.selectedElement
          console.log(graphType, 'graphType')
          this.srvHandler.setValue('isNested', false)
          if (
            graphType === 'donut' ||
            graphType === 'donut3d' ||
            graphType === 'nestedDonut' ||
            graphType === 'donutRounded'
          ) {
            this.srvHandler.setValue('isDonut', true)
          }
          if (this.selectedElement.graphType === 'nestedDonut') {
            this.srvHandler.setValue('isNested', true)
          }
        }
      } else {
        this.srvHandler.data = this.selectedElement.configuration || {}
      }
      // return;
      if (
        this.selectedElement.dataSource &&
        this.selectedElement.dataSource.headers &&
        this.selectedElement.dataSource.headers.length
      ) {
        // here in future data come from ec on the availibity of ec
        this.tabular_data.columns = this.selectedElement.dataSource.headers.map(
          (el) => el.header
        )
        // if ec data is present data will be taken from ec oderwise dummy
        if (this.selectedElement.dataSource.sources_link) {
          this.getExportData({
            name: this.selectedElement.dataSource.sources_link,
            params: {}
          }).then((res) => {
            this.tabular_data.rows = JSON.parse(JSON.stringify(res.info || []))
          })
        } else {
          this.tabular_data.rows = this.selectedElement.dataSource.rows || []
        }
      } else {
        console.log('Data sources is not provided')
      }

      let columnsSelected = this.srvHandler.data['columns'] || []
      let exportFieldSequence = this.tabular_data.columns

      // this.srvHandler.setValue('isNested', false)
      // if(this.selectedElement && this.selectedElement.graphType){
      //   let {graphType} = this.selectedElement
      //   // console.log(graphType, "graphType")
      //   if(graphType === "donut" || graphType === "donut3d", graphType === "nestedDonut" || graphType === "donutRounded"){
      //     this.srvHandler.setValue('isDonut', true)
      //   }
      //   if(this.selectedElement.graphType === 'nestedDonut'){
      //     this.srvHandler.setValue('isNested', true)
      //   }
      // }

      if (this.selectedElement.type == 'table') {
        // let splitTablePart1 = this.srvHandler.data['splitTablePart1'] || []
        // let splitTablePart2 = this.srvHandler.data['splitTablePart2'] || []
        this.srvHandler.setChoices('pivot_column', exportFieldSequence)
        this.srvHandler.setChoices('pivot_fields', exportFieldSequence)
        this.srvHandler.setChoices('sourceColumnValue', exportFieldSequence)
        this.srvHandler.setChoices('dynamicRangeColumn', exportFieldSequence)
        this.srvHandler.setChoices('searchByColumn', exportFieldSequence)
        this.srvHandler.setChoices('attachmentColumn', exportFieldSequence)
        this.srvHandler.setChoices(
          'downloadAttachmentColumn',
          exportFieldSequence
        )

        if (this.srvHandler.data.hasOwnProperty('extraVisualAidColumns')) {
          let question = this.srvHandler.getQuestionByName(
            'extraVisualAidColumns'
          )
          this.srvHandler.setChoicesInDP(
            question,
            'groupField',
            exportFieldSequence
          )
          this.srvHandler.setChoicesInDP(question, 'field', exportFieldSequence)
        }
        // this.srvHandler.setChoices('splitTablePart1', exportFieldSequence)
        this.srvHandler.setChoices('column', exportFieldSequence)
        // this.srvHandler.setChoices('splitTablePart2', exportFieldSequence)
        if (
          this.srvHandler.data.expandableSubTable &&
          this.srvHandler.data.rowSchema
        ) {
          let ds = extractColumnsForExpasionTable(
            this.srvHandler.data.rowSchema,
            this.tabular_data.rows
          )
          this.srvHandler.setChoices('expandableTableColumns', ds)
          let expandableTableColumns =
            this.srvHandler.data.expandableTableColumns || []
          if (expandableTableColumns.length)
            this.srvHandler.setValue(
              'expandableTableColumns',
              expandableTableColumns.filter((el) => ds.includes(el))
            )
        }
        if (this.srvHandler.data.splitTable) {
          let question = this.srvHandler.getQuestionByName('splitConfig')
          question.items.forEach((que) => {
            que.panel.elements.forEach((inQue) => {
              if (inQue.name === 'columns') {
                let value = inQue.value.filter((cl) =>
                  exportFieldSequence.includes(cl)
                )
                inQue.value = value
              }
            })
          })
          // this.srvHandler.setValue(
          //   'splitTablePart1',
          //   splitTablePart1.filter((el) => exportFieldSequence.includes(el))
          // )
          // this.srvHandler.setValue(
          //   'splitTablePart2',
          //   splitTablePart2.filter((el) => exportFieldSequence.includes(el))
          // )
        }
        this.srvHandler.setChoices('sortableColumns', exportFieldSequence)
        this.srvHandler.setChoices('rowSchema', exportFieldSequence)
      }

      if (
        this.selectedElement.type == 'table' ||
        this.selectedElement.type == 'html'
      ) {
        this.srvHandler.setChoices('columns', exportFieldSequence)
        if (columnsSelected.length)
          this.srvHandler.setValue(
            'columns',
            columnsSelected.filter((el) => exportFieldSequence.includes(el))
          )
      }
      this.srvHandler.setChoices('key', exportFieldSequence)
      this.srvHandler.setChoices('target', exportFieldSequence)

      /* for pie */
      this.srvHandler.setChoices('column', exportFieldSequence)
      this.srvHandler.setChoices('labels', exportFieldSequence)
      /* for serial */
      this.srvHandler.setChoices('categoryField', exportFieldSequence)
      this.srvHandler.setChoices('valueField', exportFieldSequence)
      this.srvHandler.setChoices('subtitleField', exportFieldSequence)
      /* for ultra_big_number */
      this.srvHandler.setChoices('mainNumberField', exportFieldSequence)
      this.srvHandler.setChoices('progress_bar_value', exportFieldSequence)
      /* for plain_big_number_tile */
      this.srvHandler.setChoices('smallNumberField', exportFieldSequence)
      // this.srvHandler.setChoices("remarkToneFeild", exportFieldSequence);
      /* For Bubble Chart */
      this.srvHandler.setChoices('seriesName', exportFieldSequence)
      this.srvHandler.setChoices('xAxis', exportFieldSequence)
      this.srvHandler.setChoices('yAxis', exportFieldSequence)
      this.srvHandler.setChoices('bubbleValue', exportFieldSequence)
      //for graphs
      this.srvHandler.setChoices('category', exportFieldSequence)
      this.srvHandler.setChoices('valueY', exportFieldSequence)
    },
    onChangeInput(whatChanged, data) {
      // console.log('data', data)
      // console.log("whatChanged",whatChanged)
      // console.log('this.srv :>> ', this.srvHandler);
      // console.log('jjjjjjjj', this)
      // if (!whatChanged.question) {
      //   return
      // }
      // this.srvHandler.isCurrentPageHasErrors
      // if (this.srvHandler.isCurrentPageHasErrors) return
      // whatChanged.question.hasErrors(true);
      // configuration
      //
      // this.setSelectedElementsProps({ prop: 'configuration', value: data })
      // this.$store.commit('ReportBuilder/setLocalVersionOfElementsData', {
      //   prop: 'configuration',
      //   data: data,
      //   _id: this.selectedElement._id
      // })
      this.saveChangesInLocalStore()
      this.onchangeSetValue(whatChanged)
    },
    onchangeSetValue(whatChanged) {
      this.somethingchange = true
      let choices = this.tabular_data.columns
      if (this.selectedElement.type == 'table') {
        if (whatChanged.name === 'colorIndicatorData') {
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'column',
            choices
          )
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'sourceColumnValue',
            choices
          )
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'dynamicRangeColumn',
            choices
          )
        }
        if (whatChanged.name == 'columnsMappingForSort') {
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'target',
            choices
          )
          this.srvHandler.setChoicesInDP(whatChanged.question, 'key', choices)
        }
        if (whatChanged.name == 'rowSchema') {
          let dddddd = extractColumnsForExpasionTable(
            whatChanged.value,
            this.tabular_data.rows
          )
          this.srvHandler.setChoices('expandableTableColumns', dddddd)
        }
        if (whatChanged.name == 'extraVisualAidColumns') {
          // this.srvHandler.setChoicesInDP(
          //   whatChanged.question,
          //   'column',
          //   choices
          // )
        }
        if (whatChanged.name === 'extraVisualAid') {
          let question = this.srvHandler.getQuestionByName(
            'extraVisualAidColumns'
          )
          this.srvHandler.setChoicesInDP(question, 'groupField', choices)
          this.srvHandler.setChoicesInDP(question, 'field', choices)
        }

        if (whatChanged.name === 'currencyConversion') {
          this.srvHandler.setChoices('currencyConversionOnColumn', choices)
          this.srvHandler.setChoices('field', choices)
        }
      }

      if (
        whatChanged.name == 'main_number_config' ||
        whatChanged.name == 'main_big_number_config'
      ) {
        this.$nextTick(() => {
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'mainNumberField',
            choices
          )
        })
      }
      if (whatChanged.name == 'main_big_number_config') {
        this.$nextTick(() => {
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'smallNumberField',
            choices
          )
        })
      }
      console.log('whatChanged.name', whatChanged.name)
      if (whatChanged.name == 'series') {
        this.$nextTick(() => {
          this.srvHandler.setChoicesInDP(
            whatChanged.question,
            'valueY',
            choices
          )
        })
      }

      if (this.selectedElement.type == 'html' && whatChanged.name == 'text') {
        // this.srvHandler.setValue('text', sanitizeHtml(whatChanged.value))
      }
    }
  },
  watch: {
    selectedElement() {
      // console.log(this.$store)
      // this.getConfig()
    }
  },
  created() {
    console.log('<====CONFIGURATION=====>')
    // console.log('jjjjjjjjjjjjj', this)
    this.getConfig()
  }
}
function extractColumnsForExpasionTable(columnName, data) {
  let info = []
  for (let row of data) {
    if (row[columnName]) {
      info.push(...row[columnName])
    }
  }
  return extractKeys(info)
}
function extractKeys(dataArray, knownKeys) {
  let keysCollection = {}
  if (knownKeys && extractKeys instanceof Array) {
    knownKeys.forEach((element) => {
      keysCollection[element] = element
    })
  }
  for (let idx = 0; idx <= 50 || idx <= dataArray.length; idx++) {
    let obj = dataArray[idx]
    Object.assign(keysCollection, obj)
  }
  return Object.keys(keysCollection)
}
</script>

<style>
.warning-message {
  padding: 10px;
  text-align: center;
}
.sv_main {
  position: relative;
  /* width: 90%; */
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: bolder;
  font-size: 13px !important;
}
.elem-confi .sv_panel_dynamic > input[type='button'] {
  color: #4762cf;
  background-color: transparent;
  border: 0.1em solid #4762cf;
  box-shadow: none;
  padding: 0.5em 0.5em;
}
.kbet-survey .sv_panel_dynamic > div {
  border: 1px solid #3f51b5;
  border-radius: 0.5em;
  padding: 0.25em;
  margin-bottom: 0.5em;
}
.sv_main .sv_body {
  background-color: #fafafa;
  border-top: unset;
}
.sv_main .sv_q_other input,
.sv_main .sv_q_text_root,
.sv_main input[type='text'],
.sv_main textarea {
  border: 1px solid #e7e7e7;
  color: #6d7072;
  border-radius: 10px;
  background-color: white;
}

.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #1c302f;
  border-radius: 5px 5px 0 0;
}

.sv_window_content {
  padding: 8px;
  margin: 0;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}

.sv_main.sv_frame .sv_container {
}

.sv_main.sv_frame .sv_container .sv_header {
}

.sv_main {
  background-color: white;
}
.sv_main hr {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main input[type='button'],
.sv_main button {
  color: white;
  background-color: #3f51b5;
}
/* .sv_main input[type='button']:hover,
.sv_main button:hover {
  background-color: #3f51b5;
} */
.sv_main .sv_q_other input,
.sv_main .sv_q_text_root,
.sv_main .sv_q_dropdown_control,
.sv_main input[type='text'],
.sv_main select,
.sv_main textarea {
  border: 1px solid #e7e7e7;
  color: #6d7072;
  background-color: white;
}
.sv_main .sv_q_other input:focus,
.sv_main .sv_q_text_root:focus,
.sv_main .sv_q_dropdown_control:focus,
.sv_main input[type='text']:focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border: 1px solid #3f51b5;
}
.sv_main .sv_select_wrapper {
  background-color: white;
}
.sv_main .sv_select_wrapper:before {
  background-color: #3f51b5;
}
.sv_main .sv_header {
  color: #6d7072;
}
.sv_main .sv_custom_header {
  background-color: #ffffff;
}
.sv_main .sv_container {
  color: #252323;
}
.sv_main .sv_body {
  background-color: white;
  border-top: 2px solid #3f51b5;
}
.sv_main .sv_progress {
  background-color: #e7e7e7;
}
.sv_main .sv_progress_bar {
  background-color: #3f51b5;
}
.sv_main .sv_p_root > .sv_row {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main .sv_p_root > .sv_row:nth-child(odd) {
  background-color: white;
}
.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: #f4f4f4;
}
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none']) {
  border: 1px solid #ed5565;
  background-color: #fcdfe2;
  color: #ed5565;
}
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + input,
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + textarea,
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + .sv_select_wrapper
  select {
  border: 1px solid #ed5565;
}
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + input:focus,
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + textarea:focus,
.sv_main
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none'])
  + .sv_select_wrapper
  select:focus {
  outline: #ed5565 auto 5px;
}
.sv_main table.sv_q_matrix tr,
.sv_main table.sv_q_matrix_dropdown tr,
.sv_main table.sv_q_matrix_dynamic tr {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #179d82;
  border-color: #179d82;
  color: white;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  border: 1px solid #e7e7e7;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid #179d82;
}

.sv_main {
  position: relative;
  width: 100%;
  font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans',
    'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
}
.sv_main hr {
  border: none;
}
.sv_main input[type='button'],
.sv_main button {
  font-size: 0.85em;
  font-weight: bold;
  line-height: 2em;
  border: none;
  min-width: 100px;
  cursor: pointer;
  padding: 0 2em;
  border-radius: 2px;
}
.sv_main .sv_custom_header {
  position: absolute;
  width: 100%;
}
.sv_main .sv_container {
  position: relative;
}
.sv_main .sv_container .sv_header {
}
.sv_main .sv_container .sv_header h3 {
  font-size: 2em;
  font-weight: 300;
  text-align: left;
  margin: 0.1;
}
.sv_main .sv_container .sv_body {
  padding: 0em 0em 3em 0em;
}
.sv_main .sv_container .sv_body .sv_progress {
  height: 0.3em;
}
.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  height: 100%;
  position: relative;
  margin-top: 2em;
}
.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar > span {
  position: absolute;
  top: -1.5em;
  display: inline-block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  font-size: 1em;
  margin-top: 0.1em;
  font-weight: normal;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_p_title {
  font-weight: bold;
  font-size: 1.15em;
  margin: 1.5em 0 0 0;
}
.sv_main .sv_container .sv_body .sv_p_root > .sv_row {
  padding: 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-weight: bold;
  font-size: 1em;
  margin: 0.05em 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: -0.6em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 1em 1.5em 1em;
  box-sizing: border-box;
  overflow: auto;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_panel_dynamic .sv_q_title {
  font-weight: bold;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_erbox:not([style*='display: none']):not([style*='display:none']) {
  margin: 1em 0;
  padding: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='text'],
.sv_main .sv_container .sv_body .sv_p_root .sv_q select,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 2em;
  padding-left: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='text']:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q select:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea:focus {
  outline: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_dropdown_control {
  min-height: 2.43em;
  min-width: 7em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
  display: block;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.5em;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_select_wrapper
  select::-ms-expand {
  display: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {
  padding: 1em;
  position: absolute;
  right: 1px;
  right: 0;
  top: 1px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxMiwxNiAxNCwxNCAxNywxNyAyMCwxNCAyMiwxNiAxNywyMSAiLz4KPC9zdmc+Cg==);
  content: '';
  background-repeat: no-repeat;
  background-position: center;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='color'] {
  min-height: 2em;
  padding: 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='radio'],
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='checkbox'] {
  margin: 0;
  margin-right: 0.55em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin-top: -0.1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='radio']:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type='checkbox']:focus {
  outline: none;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child) {
  margin-right: 1.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup > label,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox > label {
  white-space: nowrap;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup_inline {
  line-height: 2em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_footer {
  padding: 1em 0;
}
.sv_main .sv_container .sv_body .sv_p_root table {
  width: 100%;
  border-collapse: collapse;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix
  .sv_matrix_dynamic_button,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dropdown
  .sv_matrix_dynamic_button,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dynamic
  .sv_matrix_dynamic_button {
  padding: 0.3em 2em;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix
  .sv_q_checkbox_label,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix
  .sv_q_radiogroup_label,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dropdown
  .sv_q_checkbox_label,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dropdown
  .sv_q_radiogroup_label,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dynamic
  .sv_q_checkbox_label,
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dynamic
  .sv_q_radiogroup_label {
  margin-right: 1.5em;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
  min-width: 10em;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td .sv_q_m_label {
  position: static;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  table.sv_q_matrix_dropdown
  .sv_qcbc
  .sv_q_checkbox_control_label {
  margin-right: 1.5em;
}
.sv_main .sv_container .sv_body .sv_p_root table td,
.sv_main .sv_container .sv_body .sv_p_root table th {
  text-align: left;
  padding: 0 1em;
}
.sv_main .sv_container .sv_body .sv_p_root table td {
  padding: 0.5em;
  padding-right: 2em;
}
.sv_main .sv_container .sv_body .sv_p_root table th {
  line-height: 1.3em;
  padding: 0.5em;
  vertical-align: bottom;
  font-weight: normal;
}
.sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
  line-height: 2em;
  padding-top: 0.1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_other {
  margin-left: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  line-height: 2em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
  word-spacing: initial;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q_rating
  .sv_q_rating_item
  .sv_q_rating_item_text {
  display: inline-block;
  width: 2em;
  /* padding : 0px 15px 0px 15px; */
  margin-left: -1px;
  text-align: center;
}
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q_rating
  .sv_q_rating_item:not(:first-child)
  .sv_q_rating_item_text:not(:hover) {
  border-left-color: transparent;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
  margin-right: 0.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
  margin-left: 0.5em;
}
.sv_main .sv_container .sv_body .sv_nav {
  padding: 1em 0;
  min-height: 2em;
}
.sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  float: left;
}
.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  float: left;
}
.sv_main input[type='button'] {
  margin: 12px;
  padding: 4px;
  border-width: 1px;
  border-radius: 3px;
  border-color: #454258;
  background-color: #4456bb;
  color: #ffffff;
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12); */
}
.sv_row {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
