export default function transform(srvHandler) {
//   console.log('this.srvHandler.data', srvHandler.data)
  let chartConfig = srvHandler.data
//   console.log(chartConfig, 'chartConfig')
  let renderConfig = {
    type: chartConfig.type,
    formData: srvHandler.data
  }
  switch (renderConfig.type) {
    case 'XYChart':
    case 'XYChart3D': {
      console.log('Column bar chart:>>>>>>>>>')
      //   console.log(chartConfig.series.length, "========================")
      let seriesLength = chartConfig.series.length
      let rightAxis = false
      let smoothLine = false

      renderConfig = Object.assign(renderConfig, {
        series: chartConfig.series.map((graphConfigEl, idx) => {
        //   console.log(graphConfigEl, "graphConfigEl", graphConfigEl.chartType, renderConfig.formData)
          if(graphConfigEl.chartType == 'SmoothLine') smoothLine = true
          graphConfigEl.type =  graphConfigEl.chartType == 'SmoothLine' ? 'LineSeries' : graphConfigEl.chartType
          graphConfigEl.type = renderConfig.type == 'XYChart3D' && graphConfigEl.type !== 'LineSeries' ? graphConfigEl.type + '3D' : graphConfigEl.type
        //   console.log(graphConfigEl.chartType, "graphConfigEl.chartType>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.")
          graphConfigEl.name = graphConfigEl.seriesTitle || 'Series-' + idx
          if (graphConfigEl.type == 'ColumnSeries' || graphConfigEl.type == 'ColumnSeries3D') {
            ;(graphConfigEl.columns = {
              template: {
                type: graphConfigEl.type == 'ColumnSeries3D'? 'Column3D' : 'Column',
                strokeOpacity: 0,
                tooltipText: '{categoryX}\n{valueY}',
                tooltipPosition: 'pointer'
              }
            }),
              (graphConfigEl.bullets = {
                values: [
                  {
                    type: 'LabelBullet',
                    label: {
                      type: 'Label',
                      text: graphConfigEl.labelsFormat || '',
                      fontSize: 7
                    }
                  }
                ],
                template: {
                  type: 'Bullet'
                }
              })
          } else if (graphConfigEl.type == 'LineSeries') {
            //   console.log("graphConfigEl.chartType == 'SmoothLine'", graphConfigEl.chartType )
            graphConfigEl.bullets = {
              values: [
                {
                    "type": "LabelBullet",
                    "children": [{
                        "type": "Rectangle",
                        "width": 10,
                        "height": 10,
                        "horizontalCenter": "middle",
                        "verticalCenter": "middle"
                    }],
                    "label": {
                        "type": "Label",
                        "text": graphConfigEl.labelsFormat || '',
                        "fontSize": 10
                    }
                },
                {
                  type: 'CircleBullet',
                  tooltipText:'{name}\n{categoryX}: {valueY}'
                }
              ],
              template: {
                type: 'Bullet'
              }
            }
          }
          graphConfigEl.stacked = graphConfigEl.isStacked || false
          if (chartConfig.isHorizontal) {
              if(graphConfigEl.chartType =='SmoothLine'){
                graphConfigEl.dataFields = {
                    valueX: graphConfigEl.valueY,
                    dateY: chartConfig.category
                  }
              } else {
                  graphConfigEl.dataFields = {
                    valueX: graphConfigEl.valueY,
                    categoryY: chartConfig.category
                  }
              }
          } else {
            if(graphConfigEl.chartType =='SmoothLine') {
                graphConfigEl.dataFields = {
                    valueY: graphConfigEl.valueY,
                    dateX: chartConfig.category
                  }
            }else{
                graphConfigEl.dataFields = {
                  valueY: graphConfigEl.valueY,
                  categoryX: chartConfig.category
                }
            }
          }
          ;(graphConfigEl.sequencedInterpolation = true),
            (graphConfigEl.sequencedInterpolationDelay = 100)
            if(graphConfigEl.chartType =='SmoothLine' && idx == 0){
                // console.log(idx,"idx>>>>>>>>>>>>>>>>>")
                graphConfigEl.strokeWidth = 3,
                graphConfigEl.tensionX = 0.8
                graphConfigEl.tensionY = 0.9
            } else {
                graphConfigEl.strokeWidth = 3,
                graphConfigEl.tensionX = 0.5
            }
          if (graphConfigEl.valueAxis == 'Right') rightAxis = true
          return JSON.parse(JSON.stringify(graphConfigEl))
        }),
        legend: {
          type: 'Legend'
        }
      })
      let valueAxis=[]
      valueAxis[0] = {
        type: 'ValueAxis',
        id: 'axis1',
        title: {
          type: 'Label',
          text: chartConfig.axisTitleLeft
        },
        min: 0,
        // "max": 100,
        renderer: {
          opposite: seriesLength === 1 && rightAxis ? true : false,
          maxLabelPosition: 0.98
        }
      }
      valueAxis[1] = {
        type: 'ValueAxis',
        id: 'axis2',
        title: {
          type: 'Label',
          text: chartConfig.axisTitleRight || chartConfig.axisTitleLeft
        },
        min: 0,
        max: 10000,
        renderer: {
          opposite: true,
          maxLabelPosition: 0.98
        }
      }
      let categoryAxis
      categoryAxis = [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: chartConfig.category
          },
          renderer: {
            grid: {
              template: {
                type: 'Grid',
                location: 0
              }
            },
            minGridDistance: 20
          }
        }
      ]
      let dateAxis = [{
        "type": "DateAxis",
        "dataFields": {},
        "renderer": {
            "grid": {
                "template": {
                    "type": "Grid",
                    "location": 0
                }
            },
            "minGridDistance": 20
        }
    }]
    // console.log("dateAxis>>>>>>>>.")
      if (chartConfig.isHorizontal) {
        // console.log(seriesLength, rightAxis, 'barr')
        if(smoothLine){
            renderConfig.yAxes = dateAxis
        } else {
            renderConfig.yAxes = categoryAxis
        }
        renderConfig.xAxes = [valueAxis[0]]
        if (seriesLength > 1 && rightAxis) {
          renderConfig.xAxes[1] = valueAxis[1]
        }
        // console.log(renderConfig.xAxes, renderConfig.yAxes, "######################")
      } else {
        if(smoothLine){
            renderConfig.xAxes = dateAxis
        }else{
            renderConfig.xAxes = categoryAxis
        }
          renderConfig.yAxes =  [valueAxis[0]]
          if (seriesLength > 1 && rightAxis) {
            renderConfig.yAxes[1] = valueAxis[1]
          }
        //   console.log(renderConfig.xAxes, renderConfig.yAxes, "######################")
      }
      console.log(renderConfig, '>>>>>>>>>>>>>>>>>>>.')
      break
    }
    case 'PieChart':
    case 'PieChart3D': {
    //   console.log('PieChart:>>>>>>>', chartConfig)
      renderConfig = Object.assign(renderConfig, {
        series: chartConfig.series.map((graphConfigEl, idx) => {
            console.log(graphConfigEl, "---------------")
            graphConfigEl.dataFields = {
                value: chartConfig.isNested ? graphConfigEl.valueY : chartConfig.valueY,
                category: chartConfig.category
              }
              if(chartConfig.isRounded || (graphConfigEl.slices && graphConfigEl.slices.template && graphConfigEl.slices.template.innerCornerRadius)){
                  graphConfigEl.slices= {
                    "template": {
                        "type": "Slice",
                        "innerCornerRadius": 7,
                        "cornerRadius": 10
                    }
                }
              }
              if(chartConfig.isNested || graphConfigEl.slices && graphConfigEl.slices.template && graphConfigEl.slices.template.states){
                graphConfigEl.slices= {
                    "template": {
                        "type": "Slice",
                        "stroke": "#fff",
                        "strokeWidth": 2,
                        "strokeOpacity": 1,
                        "states": {
                            "hover": {
                                "properties": {
                                    "shiftRadius": 0,
                                    "scale": 1.1
                                }
                            }
                        }
                    }
                }
              }
              if(chartConfig.labelText){
                  graphConfigEl.labels = {
                    "template": {
                        "type": "AxisLabelCircular",
                        disabled: false
                    }
                }
              }
              return JSON.parse(JSON.stringify(graphConfigEl))
        })
    })
      if(chartConfig.isDonut || (chartConfig.radius && chartConfig.innerRadius)){
          renderConfig.radius =  "60%"
          renderConfig.innerRadius = "30%"
      }
      if(chartConfig.legend){
        renderConfig.legend= {
            "type": "Legend"
        }
      }
    }
    console.log(renderConfig, '>>>>>>>>>>>>>>>>>>>.')
    break
  }
  return renderConfig
}
